import { MediaInfo } from './types';
import { RootState } from './../root/root.store';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { create } from 'domain';
import { v4 as uuidv4 } from 'uuid';

export const IMAGE_UPLOAD_SLICE_KEY = 'uploads';

interface UploadItem {
  id: string;
  fileName: string; // Store filename instead of File object
  size: number; // Store file size for progress calculations
  progress: number;
  status: 'idle' | 'uploading' | 'completed' | 'error';
  url?: string;
  formId: string;
  testItemId?: number; // Optional test_item_id
  mediaInfo?: MediaInfo;
  error?: string;
  createdAt: string;
}

export interface UploadState {
  uploads: Record<string, UploadItem>;
  activeUploads: number;
}

const initialState: UploadState = {
  uploads: {},
  activeUploads: 0,
};

export const uploadSlice = createSlice({
  name: IMAGE_UPLOAD_SLICE_KEY,
  initialState,
  reducers: {
    addUpload: (
      state,
      action: PayloadAction<{
        id: string;
        fileName: string;
        size: number;
        formId: string;
        testItemId?: number;
      }>
    ) => {
      const { id, fileName, size, formId, testItemId } = action.payload;
      state.uploads[id] = {
        id,
        fileName,
        size,
        progress: 0,
        status: 'idle',
        formId,
        testItemId,
        createdAt: new Date().toISOString(),
      };
    },
    startUpload: (state, action: PayloadAction<string>) => {
      const upload = state.uploads[action.payload];
      if (upload) {
        upload.status = 'uploading';
        state.activeUploads += 1;
      }
    },

    updateProgress: (
      state,
      action: PayloadAction<{ id: string; progress: number }>
    ) => {
      const upload = state.uploads[action.payload.id];
      if (upload) {
        upload.progress = action.payload.progress;
      }
    },

    completeUpload: (
      state,
      action: PayloadAction<{
        id: string;
        mediaInfo: MediaInfo;
      }>
    ) => {
      const upload = state.uploads[action.payload.id];
      if (upload) {
        upload.status = 'completed';
        upload.mediaInfo = action.payload.mediaInfo;
        upload.progress = 100;
        state.activeUploads -= 1;
      }
    },

    failUpload: (
      state,
      action: PayloadAction<{ id: string; error: string }>
    ) => {
      const upload = state.uploads[action.payload.id];
      if (upload) {
        upload.status = 'error';
        upload.error = action.payload.error;
        state.activeUploads -= 1;
      }
    },

    removeUpload: (state, action: PayloadAction<string>) => {
      const upload = state.uploads[action.payload];
      if (upload?.status === 'uploading') {
        state.activeUploads -= 1;
      }
      console.log('removing upload', action.payload, state.uploads);
      state.uploads = Object.fromEntries(
        Object.entries(state.uploads).filter(([key]) => key !== action.payload)
      );

      console.log('removing upload 2', action.payload, state.uploads);
    },

    clearFormUploads: (state, action: PayloadAction<string>) => {
      const formId = action.payload;
      Object.keys(state.uploads).forEach((key) => {
        if (state.uploads[key].formId === formId) {
          if (state.uploads[key].status === 'uploading') {
            state.activeUploads -= 1;
          }
          delete state.uploads[key];
        }
      });
    },

    toggleUploadDelete: (state, action: PayloadAction<{ id: string }>) => {
      const upload = state.uploads[action.payload.id];
      if (upload?.mediaInfo) {
        upload.mediaInfo.deleted = !upload.mediaInfo.deleted;
      }
    },
  },
});

// export const selectUploads = createSelector(
//   [(state: RootState) => state.uploads.uploads],
//   (uploads) => uploads
// );

export const selectUploads = (state: RootState) => state.uploads.uploads;

// Selectors
export const selectUploadsByFormId = createSelector(
  [selectUploads, (_, formId: string) => formId],
  (uploads, formId) => {
    const filtered = Object.values(uploads).filter(
      (upload) => upload.formId === formId
    );
    return filtered;
  }
);

export const selectUploadIds = createSelector(
  [selectUploads],
  (uploads) => Object.keys(uploads)
);

export const selectUploadById = createSelector(
  [selectUploads, (_, id: string) => id],
  (uploads, id) => uploads[id]
);

export const selectActiveUploads = createSelector([selectUploads], (uploads) =>
  Object.values(uploads).filter((upload) => upload.status === 'uploading')
);

export const selectUploadStatus = createSelector([selectUploads], (uploads) =>
  Object.values(uploads).filter(
    (upload) => upload.status === 'uploading' || upload.status === 'error'
  )
);

export const selectHasActiveUploads = createSelector(
  [(state: RootState) => state.uploads.activeUploads],
  (activeUploads) => activeUploads > 0
);

export const {
  addUpload,
  startUpload,
  updateProgress,
  completeUpload,
  failUpload,
  removeUpload,
  clearFormUploads,
  toggleUploadDelete,
} = uploadSlice.actions;
